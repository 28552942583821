import { Component } from '@angular/core';
import { NbCardModule } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
  imports: [
    NbCardModule,
    TranslatePipe
  ]
})
export class Page404Component {}
